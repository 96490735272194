<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Technician Commission Payments/List</h4>
          <div>
            <router-link :to="can('technician-commission-payment-create') ? {name:'appTechnicianCommissionPaymentCreate'} : '#'">
              <span :title="can('technician-commission-payment-create') ? 'Create' : 'Create Forbidden'"
                    class="glow d-flex align-items-center">
                <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create</span>
            </router-link>
          </div>
        </div>
        <div class="users-list-filter px-1 my-2 py-2 border rounded">
          <div>
            <div class="row align-items-center ">
              <div class="col-12 col-sm-6 col-md-3 col-lg-2">
                <label>Show Per Page</label>
                <VueMultiselect v-model="selectedPagination" class="" :options="paginationOption" :close-on-select="true"
                                label="name" track-by="name" :show-labels="false" :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 pt-1">
                <div class="form-group">
                  <label>Start Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedFromDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3 pt-1">
                <div class="form-group">
                  <label>End Date</label>
                  <div class="d-flex align-items-center date-picker-wrapper">
                    <div class="w-100 ">
                      <DatePicker v-model="selectedToDate" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                  :max-date='new Date()' :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                        <template v-slot="{ inputValue ,togglePopover  }">
                          <div class="d-flex align-items-center" @click="togglePopover()" style="cursor: pointer;">
                            <i class='bx bx-calendar mr-1'></i>
                            <input class="form-control" :value="inputValue" style="cursor: pointer;"/>
                          </div>
                        </template>
                      </DatePicker>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-3 col-lg-3">
                <label>Technician</label>
                <VueMultiselect v-model="selectedTechnician" class="" :options="technicianOptions"
                                :close-on-select="true"
                                placeholder="Select status" label="name" track-by="name" :show-labels="false"
                                :allow-empty="false"/>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-1 pt-1">
                <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0"
                        @click="applyGetTechnicianCommissionPaymentListFilter(null)">Filter
                </button>
              </div>
            </div>
            <div class="row pt-2">

            </div>
          </div>
        </div>
        <div class="users-list-table">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table id="users-list-datatable" class="table">
                  <thead>
                  <tr>
                    <th class="position-relative" :style="{width:'5%'}">SL</th>
                    <th class="position-relative" :style="{width:'15%'}">REFERENCE ID</th>
                    <th class="position-relative" :style="{width:'20%'}">TECHNICIAN</th>
                    <th class="position-relative" :style="{width:'25%'}">DATE RANGE</th>
                    <th class="position-relative" :style="{width:'15%'}">GENERATED DATE</th>
                    <th class="position-relative" :style="{width:'15%'}">PAYABLE</th>
                    <th class="position-relative text-right" :style="{width:'5%'}">ACTION</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(technicianCommissionPayment,index) in technicianCommissionPayments" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                      <span>{{ technicianCommissionPayment.reference }}</span>
                    </td>
                    <td>
                      <span>{{ technicianCommissionPayment.technician?.user?.full_name }}</span>&nbsp;
                      <span>({{ technicianCommissionPayment.technician?.employee?.type }})</span>
                    </td>
                    <td>
                      <span>{{ dateFormatter(technicianCommissionPayment.from_date) }} - {{ dateFormatter(technicianCommissionPayment.to_date) }}</span>
                    </td>
                    <td>
                      <span>{{ dateFormatter(technicianCommissionPayment.created_at) }}</span>
                    </td>
                    <td>
                      ${{ (totalCommissionPayableAmountCalculation(technicianCommissionPayment) / 100).toFixed(2) }}
                    </td>
                    <td>
                      <div class="text-right">

                        <div class="dropdown py-0 px-0 view-edit-delte-icon" :style="{height:'18px'}">
                          <span type="button"
                                class="px-0 py-0"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false">
                            <i class='bx bx-dots-vertical-rounded h-100'></i>
                          </span>
                          <div class="dropdown-menu" style="min-height: 100px">
                            <router-link class="dropdown-item cursor-pointer"
                                         :title="can('technician-commission-payment-view') ? 'View' : 'View Unauthenticated'"
                                         :to="can('technician-commission-payment-view') ? {name:'appTechnicianPaymentView',params:{id:technicianCommissionPayment.id}} : '#'"> View
                            </router-link>
                            <button class="dropdown-item"
                                    data-toggle="modal"
                                    data-target="#deleteTechnicianCommissionModel"
                                    :title="can('technician-commission-payment-delete') ? 'Delete' : 'Delete Unauthenticated'"
                                    :disabled="!can('technician-commission-payment-delete')"
                                    @click="this.modelIdForDelete = technicianCommissionPayment.id">Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <DeleteAlertModal modal-name="deleteTechnicianCommissionModel"
                          :model-id="modelIdForDelete"
                          @confirmModelDeletion="confirmModelDeletion"/>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import DeleteAlertModal from '@/components/backEnd/modal/DeleteAlertModal';

import {mapActions, mapGetters} from "vuex";

import VueMultiselect from "vue-multiselect";
import {DatePicker} from "v-calendar";

import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  name: "TechnicianCommissionPaymentList",
  components: {
    AppLayout,
    DeleteAlertModal,
    VueMultiselect,
    DatePicker,
  },
  mixins: [Loader, ShowToastMessage, Authorization],
  data() {
    return {
      unauthorized: 'You are unauthorized to perform this action.',
      modelIdForDelete: null,

      selectedPagination: {
        value: null,
        name: 'Default'
      },
      selectedFromDate: null,
      selectedToDate: null,

      selectedTechnician: {
        value: null,
        name: 'None'
      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_pagination'],
      },

      getTechniciansParams: {
        status: 1,
        employee_type: [2, 3], // 2:Contractual, 3:Partner
        where_doesnt_have_franchisee_technician: 1,
        with_relation: ['user']
      },

      getTechnicianCommissionPaymentsParams: {
        technician_id: null,
        from_date: [],
        to_date: [],
        paginate: 1,
        pagination: null,
        page: null,
        with_relation: [
          'technician.employee',
          'technician.user',
          'technicianExtraCommissions',
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      technicianCommissionPayments: 'appTechnicianCommissionPayments/technicianCommissionPayments',
      paginateLinks: 'appTechnicianCommissionPayments/paginateLinks',
    }),

    paginationOption() {
      return [{
        value: this.$store.getters['appSettings/settingDefaultPagination'].value,
        name: 'Default'
      }, {
        value: 25,
        name: '25 Entries'
      }, {
        value: 50,
        name: '50 Entries'
      }, {
        value: 100,
        name: '100 Entries'
      }];
    },
    technicianOptions() {
      let activeTechnicians = this.$store.getters['appTechnicians/technicians'].map((technician) => {
        let technicianId = technician.id;
        let fullName = technician?.user?.full_name ?? '';

        return {
          value: technicianId,
          name: fullName
        };
      });

      return [{
        value: '',
        name: `None`
      }, ...activeTechnicians];
    },
  },
  watch: {
    selectedPagination(selectedPagination) {
      this.getTechnicianCommissionPaymentsParams.pagination = selectedPagination?.value;
    },
    selectedFromDate(selectedFromDate) {
      this.getTechnicianCommissionPaymentsParams.from_date[0] = selectedFromDate;
    },
    selectedToDate(selectedToDate) {
      this.getTechnicianCommissionPaymentsParams.to_date[0] = selectedToDate;
    },
    selectedTechnician(selectedTechnician) {
      this.getTechnicianCommissionPaymentsParams.technician_id = selectedTechnician?.value;
    }
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',

      getTechnicians: 'appTechnicians/getTechnicians',
      resetTechnicians: 'appTechnicians/resetTechnicians',

      getTechnicianCommissionPayments: 'appTechnicianCommissionPayments/getTechnicianCommissionPayments',
      deleteTechnicianCommissionPaymentOnList: 'appTechnicianCommissionPayments/deleteTechnicianCommissionPaymentOnList',

    }),

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getTechnicianList() {
      await this.getTechnicians(this.getTechniciansParams);
    },

    async getTechnicianCommissionPaymentList() {
      await this.getTechnicianCommissionPayments(this.getTechnicianCommissionPaymentsParams).then(async (response) => {
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    async applyGetTechnicianCommissionPaymentListFilter(pageNumber) {
      this.loader(true);
      this.getTechnicianCommissionPaymentsParams.page = pageNumber;
      await this.getTechnicianCommissionPaymentList();
      this.loader(false);
    },

    totalExtraCommissionPaymentsAdditionAmount(technicianCommissionPayment) {
      return technicianCommissionPayment?.technicianExtraCommissions?.reduce((accumulator, currentValue) => {
        if (currentValue?.commission_type === 'Addition' || currentValue?.commission_type === 0) {
          accumulator += currentValue?.amount ?? 0;
        }
        return accumulator;
      }, 0);
    },

    totalExtraCommissionPaymentsDeductionAmount(technicianCommissionPayment) {
      return technicianCommissionPayment?.technicianExtraCommissions?.reduce((accumulator, currentValue) => {
        if (currentValue?.commission_type === 'Deduction' || currentValue?.commission_type === 1) {
          accumulator += currentValue?.amount ?? 0;
        }
        return accumulator;
      }, 0);
    },
    totalExtraCommissionPaymentsTravelAllowanceAmount(technicianCommissionPayment) {
      return technicianCommissionPayment?.technicianExtraCommissions?.reduce((accumulator, currentValue) => {
        if (currentValue?.commission_type === 'Travel Allowance' || currentValue?.commission_type === 2) {
          accumulator += currentValue?.amount ?? 0;
        }
        return accumulator;
      }, 0);
    },

    totalCommissionPayableAmountCalculation(technicianCommissionPayment) {
      let commissionPaymentDetails = technicianCommissionPayment?.details;
      commissionPaymentDetails = JSON.parse(commissionPaymentDetails);

      let totalTechnicianWageAmountFromAppointment = commissionPaymentDetails?.totalTechnicianWageAmountFromAppointment ?? 0;
      let totalOrderProductCommissionAmount = commissionPaymentDetails?.totalOrderProductCommissionAmount ?? 0
      let totalExtraCommissionPaymentsAdditionAmount = this.totalExtraCommissionPaymentsAdditionAmount(technicianCommissionPayment) ?? 0
      let totalExtraCommissionPaymentsTravelAllowanceAmount = this.totalExtraCommissionPaymentsTravelAllowanceAmount(technicianCommissionPayment) ?? 0;
      let totalExtraCommissionPaymentsDeductionAmount = this.totalExtraCommissionPaymentsDeductionAmount(technicianCommissionPayment) ?? 0;

      return (
          totalTechnicianWageAmountFromAppointment
          + totalOrderProductCommissionAmount
          + totalExtraCommissionPaymentsAdditionAmount
          + totalExtraCommissionPaymentsTravelAllowanceAmount
          - totalExtraCommissionPaymentsDeductionAmount
      );

    },

    dateFormatter(dateString) {
      // create a new Date object using the input date string
      const date = new Date(dateString);

      // create an array of month names
      const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
      ];

      // get the day, month, and year from the Date object
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      // add a leading zero to the day if it's a single digit
      const formattedDay = day < 10 ? `0${day}` : day;

      // format the date string as "dd Month yyyy" and return
      return `${formattedDay} ${monthNames[monthIndex]} ${year}`;
    },

    async confirmModelDeletion(confirmModelDeletion) {
      await this.deleteSingleTechnicianCommissionPaymentOnList(confirmModelDeletion.modelId);
    },

    async deleteSingleTechnicianCommissionPaymentOnList(id) {
      this.deleteTechnicianCommissionPaymentOnList(id).then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          const toastObj = {
            message: "Technician Commission Payment Deleted",
            type: 'success'
          };
          await this.showToastMessage(toastObj);
          return;
        }
        await this.showToastMessage(response);
      })
    }
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.getTechnicianList();
    await this.getTechnicianCommissionPaymentList();
    await this.loader(false);
  },
  async beforeMount() {
    await this.resetTechnicians();
  }
}
</script>

<style scoped>

</style>